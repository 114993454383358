<template>
  <main>
    <v-card class="overflow-hidden">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" lg="6" md="6">
            <span class="green--text">Sub tipo evento requiere</span>
            <v-autocomplete
              dense
              solo
              placeholder="Seleccione un valor"
              :items="SubEventType"
              :error-messages="validationErrors.event_type_requires_id"
              item-text="name"
              item-value="id"
              v-model="eventTypeRequires.event_type_requires_id"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <span class="green--text">Texto</span>
            <v-text-field
              v-model="eventTypeRequires.text"
              :error-messages="validationErrors.text"
              solo
              dense
              placeholder="Escriba el nombre"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <span class="green--text">Valor</span>
            <v-text-field
              v-model="eventTypeRequires.value"
              :error-messages="validationErrors.value"
              solo
              dense
              placeholder="Escriba el nombre"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="text-center mt-2 mb-2">
      <v-btn small class="mx-2" color="primary" rounded @click="save()">
        Guardar
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </div>
  </main>
</template>

<script>
import {
  storeEventTypeRequiresOption,
  updateEventTypeRequiresOption,
  listEventTypeRequires,
} from "@/services/humansRights";
import sweetalert from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import getEventStateColor from "../../../utils/eventStateColors";

export default {
  props: {
    mode: {
      type: String,
      default: "create",
    },
  },
  name: "newEventTypeRequires.vue",
  data() {
    return {
      menu2: false,
      timeMenu: false,
      municipalities: [],
      territories: [],
      departments: [],
      sidewalks: [],
      zone: [],
      search: "",
      territoryTypes: [],
      SubEventType: [],
      eventTypeRequires: {
        id: "",
        texto: "",
        value: "",
        event_type_requires_id: "",
      },
      eventId: "",
    };
  },
  validations() {
    return {
      eventTypeRequires: {
        event_type_requires_id: { required },
        text: { required },
        value: { required },
      },
    };
  },
  methods: {
    addAffected(affected) {
      this.eventTypeRequires.affecteds.push(affected);
    },
    removeAffected(affected) {
      const index = this.eventTypeRequires.affecteds.indexOf(affected);
      if (index !== -1 && affected["id"] !== undefined)
        this.eventTypeRequires.affecteds[index]["remove"] = true;
      if (index !== -1 && affected["id"] === undefined)
        this.eventTypeRequires.affecteds.splice(index, 1);
    },
    async save() {
      this.$v.eventTypeRequires.$touch();
      if (this.$v.eventTypeRequires.$invalid) return;
      try {
        this.showLoader();

        const eventRequestData = {
          text: this.eventTypeRequires.text,
          value: this.eventTypeRequires.value,
          event_type_requires_id: this.eventTypeRequires.event_type_requires_id,
        };

        const storeEventResponse =
          this.mode === "edit"
            ? await updateEventTypeRequiresOption(
                this.eventTypeRequires.id,
                eventRequestData
              )
            : await storeEventTypeRequiresOption(eventRequestData);

        this.eventId = storeEventResponse.data.event_id;

        await sweetalert.fire(
          "Registro guardado correctamente",
          "",
          "success"
        );

        this.hideLoader();

        this.$router.push({
          name: "humansRights.valueReferences",
        });
      } catch (err) {
        this.hideLoader();
        console.error(err);
        sweetalert.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
    setEventData(eventData) {
      this.eventTypeRequires = eventData;
    },
    eventStateColor(state) {
      return getEventStateColor(state) || "gray";
    },
  },
  async created() {
    let eventTypesResponse = await listEventTypeRequires();
    eventTypesResponse = eventTypesResponse.data.filter(
      (item) => item.type == "OPTIONS"
    );
    this.SubEventType = eventTypesResponse;
  },
  computed: {
    validationErrors() {
      return {
        event_type_requires_id: [
          ...[
            !this.$v.eventTypeRequires.event_type_requires_id.required
              ? "este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        text: [
          ...[
            !this.$v.eventTypeRequires.text.required
              ? "este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        value: [
          ...[
            !this.$v.eventTypeRequires.value.required
              ? "este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
      };
    },
    ...mapState(["dataSource", "user"]),
  },
};
</script>

<style scoped></style>
